import React from "react";
import Sidebar from "../../Components/Sidebar";

const WorkInProgress = () => {
  return (
    <div className="flex">
      <Sidebar />
      <div className="flex-1 flex items-center justify-center bg-gray-100">
        <div className="text-center">
          <h1 className="text-4xl font-bold text-gray-800 mb-4">
            Admin Pannel
          </h1>
        </div>
      </div>
    </div>
  );
};

export default WorkInProgress;
