import React, { useState } from "react";
import {
  LayoutList,
  Palette,
  Menu,
  X,
  Building2,
  MessageSquareMore,
  Users,
  PlusSquare,
  FilesIcon,
  Images,
  LogOut
} from "lucide-react";
import { Link, useLocation } from "react-router-dom";

export default function Sidebar() {
  const location = useLocation();
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

  const Logout = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("user");
    document.cookie = "LionsID=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
    window.location.href = "/login";
  };

  let navItems;
  switch (localStorage.getItem("user")) {
    case "saurabh_llm":
      navItems = [
        { name: "Dashboard", icon: Users, href: "/dashboard" },
        { name: "Broker Links", icon: Building2, href: "/broker_links" },
        { name: "Categories", icon: LayoutList, href: "/categories" },
        { name: "New Article", icon: PlusSquare, href: "/new_post" },
        { name: "Published Articles", icon: FilesIcon, href: "/pages/publish" },
        { name: "Draft Articles", icon: FilesIcon, href: "/pages/draft" },
        { name: "Themes", icon: Palette, href: "/themes" },
        { name: "Contact Us Inquiries", icon: MessageSquareMore, href: "/contact_us" },
        { name: "Image Managment", icon: Images, href: "/img_mngmt" },
        { name: "Users", icon: Users, href: "/users" }
      ];
      break;
    case "nikhil_nnk":
      navItems = [
        { name: "Dashboard", icon: Users, href: "/dashboard" },
        { name: "Categories", icon: LayoutList, href: "/categories" },
        { name: "New Article", icon: PlusSquare, href: "/new_post" },
        { name: "Published Articles", icon: FilesIcon, href: "/pages/publish" },
        { name: "Draft Articles", icon: FilesIcon, href: "/pages/draft" },
        { name: "Themes", icon: Palette, href: "/themes" }
      ];
      break;
    default:
      Logout();
  }

  const toggleMobileMenu = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
  };

  const sidebarContent = (
    <div className="h-full flex flex-col">
      <div className="p-4">
        <h1 className="text-xl font-bold text-indigo-600">Admin Dashboard</h1>
      </div>

      <nav className="flex-1 px-4 space-y-1 overflow-y-auto">
        {navItems.map((item) => (
          <Link
            key={item.name}
            to={item.href}
            onClick={() => setIsMobileMenuOpen(false)}
            className={`flex items-center px-2 py-3 text-sm rounded-md ${
              location.pathname === item.href
                ? "bg-blue-50 text-indigo-600"
                : "text-gray-700 hover:bg-gray-50"
            }`}
          >
            <item.icon className="h-5 w-5 mr-3" />
            {item.name}
          </Link>
        ))}
      </nav>

      <div className="p-4 border-t border-gray-200">
        <button
          onClick={Logout}
          className="flex items-center w-full px-4 py-2 text-sm text-gray-700 hover:bg-gray-50 rounded-md"
        >
          <LogOut className="h-5 w-5 mr-3" />
          Logout
        </button>
      </div>
    </div>
  );

  return (
    <>
      <button
        onClick={toggleMobileMenu}
        className="lg:hidden fixed top-4 left-4 z-50 p-2 rounded-md bg-white shadow-md"
      >
        {isMobileMenuOpen ? (
          <X className="h-6 w-6 text-gray-600" />
        ) : (
          <Menu className="h-6 w-6 text-gray-600" />
        )}
      </button>

      {/* Mobile Sidebar */}
      <div
        className={`lg:hidden fixed inset-y-0 left-0 z-40 w-64 bg-white transform transition-transform duration-300 ease-in-out ${
          isMobileMenuOpen ? "translate-x-0" : "-translate-x-full"
        }`}
      >
        {sidebarContent}
      </div>

      {/* Desktop Sidebar */}
      <div className="hidden lg:block w-64 min-h-screen bg-white border-r border-gray-200">
        {sidebarContent}
      </div>

      {/* Overlay */}
      {isMobileMenuOpen && (
        <div
          className="lg:hidden fixed inset-0 bg-gray-600 bg-opacity-50 z-30"
          onClick={toggleMobileMenu}
        />
      )}
    </>
  );
}